<template>
  <div class="ua-shipping-bag">
    <GtDrawer
      direction="rtl"
      size="480px"
      title=""
      :visible.sync="shippingBagVisible"
      class="ua-shipping-bag__drawer"
      :append-to-body="true"
      :modal-append-to-body="false"
    >
      <div slot="title" class="ua-shipping-bag__drawer-title">
        {{ title || `购物袋` }}
      </div>
      <div
        v-if="loading"
        v-loading="loading"
        class="loading-img"
        element-loading-spinner="iconfont icon-other-logo1"
        element-loading-text="加载中"
      ></div>
      <section
        v-else
        class="ua-shipping-bag__drawer-wrapper"
        :style="{
          height: productsList.length || invalidProductList.length ? 'calc(100% - 90px)' : '100%'
        }"
      >
        <section
          v-if="productsList.length || invalidProductList.length"
          ref="contentRef"
          class="ua-shipping-bag__drawer-content"
          :style="{ 'padding-bottom': bottomFlag ? '0' : '0' }"
        >
          <section class="ua-shipping-bag__drawer-card">
            <ul class="product-details" :style="{ 'padding-right': bottomFlag ? '5px' : '0' }">
              <li v-for="(item, key) in productsList" :key="item.productNo">
                <section class="product-details__li">
                  <div
                    class="product-details__img"
                    :style="{
                      opacity: item.outStock ? '0.5' : '1'
                    }"
                    @click="toDetailPage(item.productNo)"
                  >
                    <GtImage
                      :src="getUrlWithOss(item.picture, {})"
                      :alt="item.skuName"
                      aspect-ratio="121.75%"
                    ></GtImage>
                  </div>
                  <div class="product-details__info common-cart-tag">
                    <div class="name">
                      <PdtCornerLabel
                        v-if="
                          item.spuCampaign &&
                          !['2000', '4000', '6002'].includes(item.spuCampaign.activityType)
                        "
                        style="
                          display: inline-block;
                          margin-right: 3px;
                          position: relative;
                          top: 2px;
                        "
                        :abs-position="false"
                        mini
                        :data="{
                          text: item.spuCampaign && item.spuCampaign.activityLabel,
                          type: item.spuCampaign && item.spuCampaign.activityType
                        }"
                      />
                      {{ item.skuName }}
                    </div>

                    <div class="attr">
                      <ul>
                        <li v-for="(item2, key) in item.attribute" :key="item2.attributeCode">
                          {{ `${item2.attributeValueNames || ''}` }}
                          <span
                            v-if="
                              item2.attributeCode === COLOR_CODE &&
                              item.spuAttributes &&
                              item.spuAttributes.filter(i => {
                                return i.attributeCode === 'PDP_COLORCODE';
                              }).length
                            "
                          >
                            ({{
                              item.spuAttributes.filter(i => {
                                return i.attributeCode === 'PDP_COLORCODE';
                              })[0].attributeValueNames
                            }})
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      v-if="item.spuCampaign && item.spuCampaign.activityType === '6000'"
                      class="activity-end-time"
                      style="margin-bottom: 8px"
                    >
                      {{ `距结束还剩` }}
                      <van-count-down :time="item.closeTime"></van-count-down>
                    </div>
                    <div
                      v-if="item.spuCampaign && item.spuCampaign.activityType === '05'"
                      style="color: var(--sticker-inf-color-Neon-orange, #ff682c)"
                    >
                      {{ item.shippingTimeDesc }}
                    </div>
                    <div>
                      <p
                        v-if="item.stock < 10 && item.stock > 0 && !item.outStock"
                        class="stock-tip"
                      >
                        库存紧张
                      </p>
                      <p v-if="item.outStock" class="stock-tip">缺货，请选择其他规格</p>
                      <p v-if="item.uaPurchaseConstraintResponse" class="stock-tip">
                        {{
                          item.uaPurchaseConstraintResponse &&
                          item.uaPurchaseConstraintResponse.purchaseConstraintText
                        }}
                      </p>
                    </div>
                    <div class="bottom">
                      <div class="left">
                        <div
                          v-if="
                            item.currPrice < item.price ||
                            (item.promotionPrice < item.price && item.currPrice != item.price)
                          "
                        >
                          <span
                            :style="{
                              color:
                                item.spuCampaign && item.spuCampaign.activityType === '6000'
                                  ? '#DC0019 !important'
                                  : '#14141E'
                            }"
                          >
                            {{
                              item.spuCampaign && ['6001'].includes(item.spuCampaign.activityType)
                                ? item.spuCampaign && item.spuCampaign.flashPrice
                                : item.currPrice | $PriceFloat
                            }}
                          </span>
                          <span>{{ item.price | $PriceFloat }}</span>
                        </div>
                        <span
                          v-else
                          :style="{
                            color:
                              item.spuCampaign && item.spuCampaign.activityType === '6000'
                                ? '#DC0019 !important'
                                : '#8c8c96'
                          }"
                        >
                          {{
                            item.spuCampaign && ['6001'].includes(item.spuCampaign.activityType)
                              ? item.spuCampaign && item.spuCampaign.flashPrice
                              : item.price | $PriceFloat
                          }}
                        </span>
                      </div>
                      <GtInputNumber
                        v-if="!item.outStock && item.stock"
                        v-model="item.quantity"
                        v-limit-tips="maxStock(item)"
                        class="common-input-number"
                        :size="isMobile ? 'mini' : 'small'"
                        :max="maxStock(item)"
                        :min="1"
                        :disabled="
                          item.uaPurchaseConstraintResponse &&
                          item.uaPurchaseConstraintResponse.isAvailablePurchase
                        "
                        @change="inputNumFn(item, $event)"
                      />
                    </div>
                  </div>
                </section>
                <div
                  v-if="item.activityList && item.activityList.length"
                  class="promotion-activity"
                  style="margin-bottom: 28px"
                >
                  <template v-for="activity in item.activityList">
                    <div
                      v-if="activity.activityType === '01' && activity.activityName"
                      :key="activity.activityCode"
                      class="activity-label-box"
                    >
                      <p class="activity-high">
                        {{ activity.activityName }}
                      </p>
                    </div>
                  </template>
                </div>
              </li>
              <!-- 失效商品 -->
              <li
                v-for="(item, key) in invalidProductList"
                :key="item.productNo"
                class="product-details__li"
                style="opacity: 0.6"
              >
                <div class="product-details__img" @click="toDetailPage(item.productNo)">
                  <GtImage
                    :src="getUrlWithOss(item.picture, {})"
                    :alt="item.skuName"
                    aspect-ratio="121.75%"
                  ></GtImage>
                  <p class="picture-tip">已下架</p>
                </div>

                <div class="product-details__info">
                  <div class="name">
                    {{ item.skuName }}
                  </div>
                  <!-- <div class="attr">
                    <ul>
                      <li v-for="(item2, key) in item.attribute" :key="key">
                        {{ `${item2.attributeValueNames || ''}` }}
                        <span
                          v-if="
                            item2.attributeCode === COLOR_CODE &&
                            item.spuAttributes &&
                            item.spuAttributes.filter(i => {
                              return i.attributeCode === 'PDP_COLORCODE';
                            }).length
                          "
                        >
                          ({{
                            item.spuAttributes.filter(i => {
                              return i.attributeCode === 'PDP_COLORCODE';
                            })[0].attributeValueNames
                          }})
                        </span>
                      </li>
                      <li style="color: var(--secondary-color-ua-cool-grey-02, #b4b4bf)">
                        商品已下架
                      </li>
                    </ul>
                  </div> -->
                  <div class="bottom">
                    <!-- <span @click="deleteCart(item)">删除</span> -->
                    <div class="left">
                      <span>{{ item.price | $ }}</span>
                      <span>{{ item.promotionAmount | $ }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </section>
        </section>

        <section v-else class="ua-shipping-bag__drawer-empty">
          <GtEmpty class="custom">
            <GtImage
              :src="require(`@/assets/image/other-null-cart.svg`)"
              alt="loading"
              aspect-ratio="121.75%"
            />
            <p slot="text" class="empty_text">您的购物袋为空</p>
          </GtEmpty>
          <!-- <p v-if="isLogin && !isGuest" class="ua-shipping-bag__drawer-title">我的收藏</p>
          <div v-if="isLogin && !isGuest" class="ua-shipping-bag__collect-container">
            <div v-if="wishlist.length" style="padding-top: 16px; position: relative">
              <GtSwiper :data="wishlist || []" :swiper-option="swiperOption" class="swiper-father">
                <div slot="default" slot-scope="{ data }" class="swiper-detail">
                  <div style="cursor: pointer" @click="toDetailPage(data.productNo)">
                    <GtImage
                      class="wishImage"
                      :src="getUrlWithOss(data.imgLink[0], {})"
                      aspect-ratio="121.75%"
                      :alt="data.productName"
                      @click="close"
                    />
                  </div>
                  <img
                    class="delete-wish"
                    src="@/assets/image/black.svg"
                    alt="black.svg"
                    @click="deleteWish(data)"
                  />
                  <div
                    class="info"
                    :class="{
                      'gray-efficacy': data.status === 0
                    }"
                  >
                    <p>{{ data.productName }}</p>
                    <span>{{ data.salePrice | $PriceFloat }}</span>
                  </div>
                </div>

                <div slot="pagination">
                  <div class="swiper-button-next">
                  </div>
                  <div class="swiper-button-prev">
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
              </GtSwiper>
            </div>
            <div v-else>
              <GtEmpty class="custom">
                <p slot="text" class="empty_text">您的收藏夹为空</p>
              </GtEmpty>
            </div>
          </div> -->
        </section>
        <section
          v-if="productsList.length || invalidProductList.length"
          class="ua-shipping-bag__drawer-bottom"
        >
          <span>共{{ quantity }}件</span>
          <div class="price">
            <span>合计</span>
            <span class="price__num">{{ checkPrice.totalAmount | $ }}</span>
          </div>
          <GtButton size="medium" class="cart-btn-high" @click="handleClick">去购物袋</GtButton>
        </section>
      </section>
      <!-- 
      <GtButton
        v-if="loading"
        size="medium"
        class="nodata_button cart-btn-high"
        @click="handleClick"
      >
        查看购物袋
      </GtButton> -->
    </GtDrawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  GtDrawer,
  GtEmpty,
  GtButton,
  GtImage,
  GtMessage,
  GtSwiper,
  GtInputNumber
} from '@speedshop/template';
import { useProduct, useWishList } from '@speedshop/sdk-api';
import NP from 'number-precision';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { queryUAMemberWishList } from '@/graphql/wishList.gql';
import { getImgLink } from '@/utils/tools';
import { useUaShopCart } from '@/utils/cart.js';
import ossImage from '@/mixins/ossImage';
import { SaleAttributeCodeMapData } from '@/plugins/enmus';
import PdtCornerLabel from '@/components/PDP/PdtCornerLabel.vue';
import { limitTips } from '@/pages/pdp/lib/directives';

export default {
  name: 'ShippingBagDrawer',
  directives: { limitTips },
  components: { GtDrawer, GtEmpty, GtButton, GtImage, GtSwiper, GtInputNumber, PdtCornerLabel },
  mixins: [ossImage],
  data() {
    return {
      loading: false,
      title: '',
      shippingBagVisible: false,
      activeSkuList: [],
      checkPrice: '',
      productsList: [],
      bottomFlag: false,
      wishlist: [],
      wishListInstance: null,
      swiperOption: {
        spaceBetween: 16,
        slidesPerView: 2,
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      invalidProductList: [],
      COLOR_CODE: SaleAttributeCodeMapData.COLOR_CODE
    };
  },
  computed: {
    ...mapGetters(['isGuest', 'isLogin', 'isMobile']),
    sum() {
      let result = 0;
      if (this.productsList.length) {
        for (let i = 0; i < this.productsList.length; i++) {
          const item = this.productsList[i];
          const product = NP.times(item.quantity, item.promotionPrice);

          result += product;
        }
      }

      return result;
    },
    quantity() {
      let noInvalid = 0;
      let invalid = 0;
      noInvalid = this.productsList.reduce(
        (accumulator, currentValue) => accumulator + currentValue.quantity,
        0
      );
      invalid = this.invalidProductList.reduce(
        (accumulator, currentValue) => accumulator + currentValue.quantity,
        0
      );
      return noInvalid + invalid;
    }
  },
  watch: {
    productsList: {
      handler(val, old) {
        this.heightFlag();
        if (val.length) {
          val.forEach(item => {
            const time = dayjs(item.spuCampaign?.shippingTime);
            item.closeTime = dayjs(item.spuCampaign?.activityEnd, 'YYYYMMDDHHmmss').diff(dayjs());
            item.shippingTimeDesc = `${time.format('M')}月${time.format('D')}号后统一发货`;
          });
        }
      },
      deep: true
    },
    shippingBagVisible: {
      handler(val, old) {
        if (!val) {
          this.$store.commit('UPDATE_MINI_SHAPPING', { flag: false, title: '' });
        }
      },
      deep: true
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.wishListInstance = useWishList();

    // if (this.isLogin) {
    //   this.getData();
    // }

    // if (this.isLogin && !this.isGuest) {
    //   // this.collectList();
    // }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    maxStock(item) {
      let result = 5;
      if (this.$store.state.accountInfo.vip === 1) {
        result = item.stock;
      } else {
        result = Math.min(
          item.stock || Infinity,
          Number(item.uaPurchaseConstraintResponse?.purchaseConstraintValue || '0') || Infinity,
          5
        );
      }
      return result;
    },
    inputNumFn: debounce(async function (item, value) {
      try {
        const {
          productCode,
          skuCode,
          advancedPriceFlag,
          promotionAmount,
          promotionPrice,
          activityCode = '',
          activityType = ''
        } = item;
        const params = {
          distributionType: '11',
          newProductCode: productCode,
          newQuantity: value,
          newSkuCode: skuCode,
          productCode,
          skuCode,
          priceSetting: String(Number(advancedPriceFlag || false)),
          promotionAmount,
          promotionPrice
        };
        const shopCart = useUaShopCart({ distributionType: '11' });
        await shopCart.updateProductSku({
          ...params
        });
        const { totalAmount } = await shopCart.queryCartProductList();
        this.checkPrice.totalAmount = totalAmount;
        this.updateCartNum();
        this.$eventBus.$emit('updateCartKey');
        this.handleTrack(item);

        this.loading = false;
      } catch (errorData) {
        console.log(errorData, 'asfdgfhgjh');
        if (errorData?.errorCode === '10144326') {
          GtMessage.error(errorData.errorMessage);
          item.quantity = errorData?.purchaseConstraintValue;
          return;
        }
        const error =
          errorData.graphQLErrors && errorData.graphQLErrors.length && errorData.graphQLErrors[0];
        if (error?.code === '10144322') {
          item.quantity = item.oldQuantity;
        } else {
          item.quantity = item.oldQuantity;
          if (errorData.errorMessage) {
            GtMessage.error(errorData.errorMessage);
          }
        }
      }
    }, 200),
    handleResize() {
      this.close();
    },

    toDetailPage(itemCode) {
      const route = { name: 'pdp', params: { code: itemCode } };
      const url = this.$router.resolve(route).href;

      window.open(url, '_blank');
      this.handleTrackData(url);
      this.close();
    },

    async show({ title = '' }) {
      this.title = title;
      this.shippingBagVisible = true;
      await new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 5);
      });
      await this.getData();
      if (this.isLogin && !this.isGuest) {
        // await this.collectList();
      }
      this.heightFlag();
    },

    close() {
      this.title = '';
      this.shippingBagVisible = false;
    },

    toShopCart() {
      this.$router.push({ path: 'shopCart' });
    },

    heightFlag() {
      this.$nextTick(() => {
        // 视口减去title 的高度
        const drawerBody = window.innerHeight - 65 - 16;
        const son = this.$refs.contentRef?.clientHeight + 161;
        this.bottomFlag = son >= drawerBody;
        console.log(son, drawerBody, this.bottomFlag, '内容 body 值');
      });
    },

    async getData() {
      try {
        this.loading = true;
        const shopCart = useUaShopCart({ distributionType: '11' });
        const data = await shopCart.init({});
        const {
          invalidProductList,
          cartProductData: { products = [], allSelected, ...checkPrice },
          activeSkuList = [],
          miniCartNum
        } = data || {};
        this.checkPrice = checkPrice;
        this.activeSkuList = activeSkuList;
        this.productsList = products;
        this.productsList.forEach(item => {
          item.oldQuantity = item.quantity;
        });
        this.invalidProductList = invalidProductList;
        this.loading = false;
      } catch (error) {
        console.log(error, '查询');
      }
    },
    handleTrack(data) {
      const {
        categoryNames = [],
        activityList,
        attribute,
        skuCode,
        productName,
        productNo,
        skuNo,
        currPrice,
        price,
        tags = [],
        spuAttributes = [],
        mainProductNo,
        quantity
      } = data;
      const modifyButtonName = [];

      modifyButtonName.push('修改增加数量');
      const size = attribute.find(i => i.attributeCode === 'SIZE') || {};
      const color = attribute.find(i => i.attributeCode === this.COLOR_CODE) || {};

      const gender = spuAttributes.find(i => i.attributeCode === 'PDP_SEX') || {};
      const colorCode = spuAttributes.find(i => i.attributeCode === 'PDP_COLORCODE') || {};
      const previousPageData = this.$previousPage.getPreviousPageData();
      this.$trackHelp.track('shoppingCartModify', {
        ...previousPageData,
        modify_button_name: modifyButtonName,
        primary_category: categoryNames[0],
        secondary_category: categoryNames[1],
        third_category: categoryNames[2],
        activity_attribute: activityList?.map(i => i.activityLabel).filter(i => i),
        gender: gender.attributeValueNames || '中性',
        sku_id: productNo,
        spu_id: mainProductNo,
        spu_name: productName,
        sales_price: currPrice,
        is_discount: currPrice < price,
        product_quantity: 0,
        list_price: price,
        color: `${color.attributeValueNames}(${colorCode.attributeValueNames})`,
        size: size.attributeValueNames
      });
    },
    async deleteCart(item) {
      try {
        this.loading = true;
        const shopCart = useUaShopCart({ distributionType: '11' });
        await shopCart.deleteSingleProduct({
          ...item
        });
        this.handleTrack(item);
        GtMessage.success('删除成功');
        await this.getData();
        this.updateCartNum();
        this.$eventBus.$emit('updateCartKey');
        this.loading = false;
      } catch (error) {
        console.log(error, '删除');
        this.loading = false;
      }
    },

    async collectList() {
      const {
        data: { api }
      } = await this.wishListInstance.gqlClient.query(queryUAMemberWishList, {
        input: {
          productCode: '',
          restrictionFlag: true
        },
        pageInput: {
          pageNum: 1,
          pageSize: 20
        }
      });
      const list = api.dataList.map(item => {
        const product = { ...item.data.product };
        product.imgLink = getImgLink(product.mediaLink);
        return product;
      });
      this.wishlist = list;
    },

    async deleteWish(data) {
      await this.wishListInstance.deleteWish({ productCode: data.productCode });
      if (this.isLogin && !this.isGuest) {
        this.collectList();
      }
    },

    async updateCartNum() {
      try {
        const res = await useProduct().getMiniCartNum();
        this.$store.commit('setMiniCartNum', res);
      } catch (error) {
        console.log(error, 'commit');
      }
    },

    handleClick() {
      this.close();
      this.$nuxt.$router.push({
        name: 'shopCart'
      });
    },
    handleTrackData(href) {
      const previousData = {
        source_url: this.$route.path,
        page_source: this.$route.meta.title || this.$route.name
      };
      const path = (href || '/').replace(window.location.origin, '');
      this.$trackHelp.cachePageData(path, previousData);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.gt-drawer .el-drawer__header {
  margin: 0;
  padding: 40px 24px 28px 40px;

  button {
    position: absolute !important;
    right: 40px !important;
    top: 40px !important;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
  }
}

::v-deep.el-image .el-image__inner {
  object-fit: cover !important;
}

.nodata_button {
  width: 320px;
  height: 48px;
  color: var(--main-color-ua-white, #fff) !important;
  background-color: var(--main-color-ua-dark-grey, #14141e) !important;
  font-size: 14px;
  font-weight: 600;
  border-radius: 1px;
  position: fixed;
  bottom: 56px;
  right: 56px;
}

.ua-shipping-bag {
  position: relative;
  &__drawer-title {
    color: var(--main-color-ua-dark-grey, #14141e);
    font-size: 24px;
    font-weight: 600;
    height: 30px;
    line-height: 22px;
  }

  &__drawer-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 0 0 40px;
  }
  &__drawer-empty {
    padding-right: 40px;
  }

  &__drawer-content {
    position: relative;
  }

  &__drawer-card {
    .product-details {
      width: 400px;
      border-bottom: 1px dashed var(--line-color-line-grey-02, #eee);

      &__li {
        height: 125px;
        display: flex;
        align-items: center;
        margin: 28px 0 20px;
      }

      &__img {
        position: relative;
        cursor: pointer;
        margin-right: 20px;
        width: 100px;
        height: 100%;
        background: #f0f0f0;

        .gt-image-container {
          width: 100%;
          height: 100%;
        }
        .picture-tip {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 32px;
          line-height: 32px;
          background: var(--darkgrey-60, rgba(20, 20, 30, 0.6));
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
        }
      }

      &__info {
        height: 100%;
        width: calc(100% - 120px);
        display: flex;
        flex-direction: column;
        .activity-name-high {
          display: inline-block;
          background: #ff682c;
          border: 1px solid #ff682c;
          color: var(--main-color-ua-white, #fff);
          text-align: center;
          font-size: 12px;
          font-weight: 600;
          width: 36px;
          height: 16px;
          line-height: 12px;
          margin-right: 4px;
        }
        .name {
          min-height: 26px;
          color: var(--main-color-ua-dark-grey, #14141e);
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
        }

        .attr {
          margin: 4px 0;
          color: var(--secondary-color-ua-cool-grey-04, #50505a);
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-transform: uppercase;
          ul {
            display: flex;
            gap: 12px;
          }
        }

        .bottom {
          font-size: 16px;
          color: var(--main-color-ua-dark-grey, #14141e);
          margin-top: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            span:first-child {
              margin-right: 8px;
            }
            span:nth-child(2) {
              color: var(--secondary-color-ua-cool-grey-03, #8c8c96);
              font-weight: 400;
              text-decoration: line-through;
            }
          }
        }
      }
    }
    li:first-child {
      .product-details__li {
        margin-top: 0;
      }
    }
  }

  &__collect-container {
    margin-top: 16px;
    border-top: 1px solid #eeeeee;
  }

  &__drawer-bottom {
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.05);
    background: var(--main-color-ua-white, #fff);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--main-color-ua-dark-grey, #14141e);
    font-size: 16px;
    font-weight: 400;

    .price {
      &__num {
        font-weight: 600;
      }
    }

    .el-button {
      margin-left: auto;
      width: 150px;
      height: 48px;
      color: var(--main-color-ua-white, #fff) !important;
      background-color: var(--main-color-ua-dark-grey, #14141e) !important;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .wishImage {
    height: 200px;
    object-fit: cover;
  }
}
</style>
<style lang="scss">
.common-cart-tag {
  .ua-corner-lb__inner {
    padding: 0;
    width: auto;
    min-width: 36px;
    height: 16px;
    display: flex;
    align-items: center;
    span {
      padding: 0 6px;
      line-height: 16px;
    }
  }
}
.stock-tip {
  color: var(--main-color-ua-red, #dc0019);
  font-size: 12px;
  font-weight: 400;
}
.ua-shipping-bag__drawer {
  .ua-shipping-bag__drawer-wrapper::-webkit-scrollbar {
    width: 4px;
  }
  .ua-shipping-bag__drawer-wrapper::-webkit-scrollbar-thumb {
    background: var(--main-color-ua-dark-grey, #14141e);
    border-radius: 5px;
    height: 20px !important;
  }
  .ua-shipping-bag__drawer-wrapper::-webkit-scrollbar-track {
    background-color: #fff;
  }
  .el-drawer__body::-webkit-scrollbar {
    width: 4px;
  }

  .el-drawer__body::-webkit-scrollbar-thumb {
    background: var(--main-color-ua-dark-grey, #14141e);
    border-radius: 5px;
    height: 20px !important;
  }

  .el-drawer__body::-webkit-scrollbar-track {
    background-color: #fff;
  }
}

.ua-shipping-bag__drawer {
  .el-drawer {
    // padding-right: 40px !important;
    .el-drawer__body {
      overflow-x: hidden;
    }
  }
}
.cart-btn-high:hover {
  opacity: 1 !important;
}
.custom {
  height: 200px;
  margin-left: 0 !important;
  .gt-empty__wrapper {
    display: flex;
    flex-direction: column !important;
  }
  .gt-image-container {
    background: #fff;
    width: 48px;
    height: 48px;
  }

  .empty_text {
    color: var(--secondary-color-ua-cool-grey-03, #8c8c96);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
  }
}

.loading-img {
  width: 60px !important;
  height: 12px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 50px 30px !important;
  color: transparent !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swiper-father {
  overflow: hidden;
  .swiper-detail {
    position: relative;
    .delete-wish {
      position: absolute;
      right: 8px;
      top: 8px;
      cursor: pointer;
    }
    .info {
      margin: 16px 0 0 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        color: var(--main-color-ua-dark-grey, #14141e);
      }
      span {
        color: var(--secondary-color-ua-cool-grey-04, #50505a);
      }
    }
    .gray-efficacy {
      p,
      span {
        color: var(--secondary-color-ua-cool-grey-04, #50505a);
        opacity: 0.400000006;
      }
    }
  }
  .swiper-button-disabled {
    display: none !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 52px !important;
    height: 52px !important;
    margin-top: 0px !important;
    border-radius: 50% !important;
    opacity: 1 !important;
    background-color: rgba(0, 0, 0, 0) !important;
    z-index: 99 !important;
    top: 90px !important;
    &::after {
      display: none;
    }
  }
  .swiper-button-next {
    right: -25px;
  }
  .swiper-button-prev {
    left: -25px;
  }
}
.promotion-activity {
  display: flex;
  width: 100%;
  height: 18px;
  // white-space: nowrap;
  overflow: hidden;
  text-align: left;
  // text-overflow: ellipsis;
  // transform: scaleX(-1);
  .activity-label-box {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }

  .activity-high {
    display: inline-block;
    width: auto;
    max-width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    text-overflow: ellipsis;
    // transform: scaleX(-1);
    // margin-left: 8px;
    color: var(--secondary-color-ua-cool-grey-03, #8c8c96);
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    height: 18px;
    padding: 0 8px;
    margin: 0 8px;
    border: 0.5px solid var(--secondary-color-ua-cool-grey-03, #8c8c96);
  }
  .activity-label-box:first-child {
    p {
      margin: 0;
    }
  }
  .activity-label-box:nth-child(n + 3) {
    p {
      margin-left: 0;
    }
  }
  .activity-label-box:last-child {
    p {
      margin-right: 0;
    }
  }
}
.member-color {
  background: linear-gradient(180deg, #000000, #670100) !important;
}
</style>
